import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {Typography} from '@mui/material';
import {ParcelStatus, ParcelStatusHistory} from 'src/types/delivery/Delivery';

import {getStatusName} from 'src/types/delivery/Delivery';
import moment from 'moment';

import useStyles from './StatusTimeline.styles';
import {useEffect, useState} from 'react';

type FormattedStatus = {
  timestamp: string;
  status: ParcelStatus;
};

const StatusTimeline = (props: {
  statusHistory: ParcelStatusHistory;
  deadline?: Date;
  color?: string;
}) => {
  const classes = useStyles(props);
  const {statusHistory, deadline} = props;
  const [statusHistoryFormatted, setStatusHistoryFormatted] = useState<FormattedStatus[]>();

  const getFormattedDateString = (epoch: any) => {
    const utcDate = moment(epoch, 'X');
    const offset = moment().utcOffset();
    const hour = utcDate.utcOffset(offset).format('h:mm A');
    const dayAndMonth = utcDate.utcOffset(offset).format('DD MMMM');
    const weekDayName = utcDate.utcOffset(offset).format('dddd');

    return `${weekDayName} ${dayAndMonth.toString()} by ${hour.toString()}`;
  };

  const isLatestStatus = (total: number, i: number) => total - 1 === i;
  const isSecondAttempt = (index: number) => {
    const firstOccurrence = statusHistoryFormatted?.findIndex(
      (el) => el.status === ParcelStatus.UnsuccessfulAttempt
    );
    return firstOccurrence && firstOccurrence >= 0 && firstOccurrence < index;
  };

  const sortByDate = (arr: FormattedStatus[]) =>
    arr.sort(
      (d1, d2) =>
        new Date(d1.timestamp).getTime() - new Date(d2.timestamp).getTime()
    );

  const omitHistoryDuplicates = (history: FormattedStatus[]): FormattedStatus[] => {
    return history.filter((entry, index, array) => {
      if (index === 0) {
        return entry;
      }
      if (entry.status === ParcelStatus.ReadyForPickup || entry.status === ParcelStatus.DriverAssigned || entry.status === ParcelStatus.OutForDelivery) {
        if (entry.status !== array[index - 1].status) {
          return entry;
        }
      } else {
        return entry
      }
    });
  }

  const formatStatusHistory = () => {
    const formattedHistory = Array.isArray(statusHistory) ? statusHistory : Object.entries(statusHistory).map((entry) => ({
      timestamp: moment(entry[1]).format(),
      status: entry[0] as ParcelStatus,
    }));
    const sortedHistory = sortByDate(formattedHistory);
    const uniqueHistory = omitHistoryDuplicates(sortedHistory);
    setStatusHistoryFormatted(uniqueHistory);
  };

  useEffect(() => {
    statusHistory && formatStatusHistory();
  }, [statusHistory]);

  return (
    <Timeline className={classes.timelineWrapper} id='tracker-status-timeline'>
      {statusHistoryFormatted &&
        statusHistoryFormatted.length > 0 &&
        statusHistoryFormatted.map(({status, timestamp}, i, arr) => (
          <TimelineItem
            className={classes.timelineItem}
            key={`tracker-status-timeline-item-${i}-${status}`}
            id={`tracker-status-timeline-item-${i}`}
          >
            <TimelineSeparator className={classes.timelineSeparator}>
              <TimelineConnector className={classes.timelineLine}/>
              <TimelineDot
                className={classes.timelineDot}
                id={`tracker-status-timeline-item-${i}-dot`}
              >
                <svg
                  className={classes.timelineDotIcon}
                  width='8'
                  height='6'
                  viewBox='0 0 8 6'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M6.96803 0.663096C7.17545 0.882526 7.17758 1.24057 6.97278 1.4628L3.25232 5.08333L1.02722 3.08547C0.822422 2.86324 0.824545 2.5052 1.03196 2.28577C1.23938 2.06634 1.57355 2.06861 1.77836 2.29085L3.25232 3.47363L6.22164 0.668177C6.42644 0.445941 6.76061 0.443666 6.96803 0.663096Z'
                    fill='#FDB13F'
                  />
                </svg>
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                className={classes.timelineBoxDate}
                id={`tracker-status-timeline-item-${i}-date`}
              >
                {moment(timestamp).format('DD.MM.YYYY, h:mm A')}
              </Typography>
              {status === ParcelStatus.OutForDelivery &&
              deadline &&
              isLatestStatus(arr.length, i) ? (
                <Typography
                  className={classes.timelineBoxStatus}
                  id={`tracker-status-timeline-item-${i}-status`}
                >
                  Out for delivery, estimated arrival on{' '}
                  {getFormattedDateString(deadline)}
                </Typography>
              ) : status === ParcelStatus.UnsuccessfulAttempt &&
              isLatestStatus(arr.length, i) &&
              isSecondAttempt(i) ? (
                <Typography
                  className={classes.timelineBoxStatus}
                  id={`tracker-status-timeline-item-${i}-status`}
                >
                  Second unsuccessful attempt. Text us (
                  <a href='sms:+61485813243'>0485 813 243</a>) to arrange
                  re-delivery
                </Typography>
              ) : status === ParcelStatus.UnsuccessfulAttempt &&
              deadline &&
              isLatestStatus(arr.length, i) ? (
                <Typography
                  className={classes.timelineBoxStatus}
                  id={`tracker-status-timeline-item-${i}-status`}
                >
                  Unsuccessful attempt, scheduled for re-delivery on{' '}
                  {getFormattedDateString(deadline)}
                </Typography>
              ) : (
                <Typography
                  className={classes.timelineBoxStatus}
                  id={`tracker-status-timeline-item-${i}-status`}
                >
                  {getStatusName(
                    status as ParcelStatus,
                    undefined,
                    undefined,
                    true
                  )}
                </Typography>
              )}
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
};

export default StatusTimeline;
