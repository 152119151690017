/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCourierPhotoQueryResult } from '../models/GetCourierPhotoQueryResult';
import type { GetCourierPositionQueryResult } from '../models/GetCourierPositionQueryResult';
import type { GetPodByTrackingIdQueryResult } from '../models/GetPodByTrackingIdQueryResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TrackingService {

  /**
   * @param trackingId
   * @returns GetPodByTrackingIdQueryResult Pod and parcel information
   * @throws ApiError
   */
  public static getProofOfDeliveryByTrackingId(
    trackingId: string,
  ): CancelablePromise<GetPodByTrackingIdQueryResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tracking/{trackingId}/pod',
      path: {
        'trackingId': trackingId,
      },
      errors: {
        404: `Entity not found`,
      },
    });
  }

  /**
   * @param trackingId
   * @returns GetCourierPositionQueryResult Ok
   * @throws ApiError
   */
  public static getCourierPosition(
    trackingId: string,
  ): CancelablePromise<GetCourierPositionQueryResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tracking/{trackingId}/courierPosition',
      path: {
        'trackingId': trackingId,
      },
      errors: {
        404: `Entity not found`,
      },
    });
  }

  /**
   * @param trackingId
   * @returns GetCourierPhotoQueryResult Ok
   * @throws ApiError
   */
  public static getCourierPhoto(
    trackingId: string,
  ): CancelablePromise<GetCourierPhotoQueryResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/tracking/{trackingId}/courierPhoto',
      path: {
        'trackingId': trackingId,
      },
      errors: {
        404: `Entity not found`,
      },
    });
  }

}