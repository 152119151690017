import React from 'react';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  GetParcelModel,
  getStatusClassName,
  getStatusName,
  ParcelDeliveryType,
  ParcelStatus,
} from 'src/types/delivery/Delivery';
import { Box, Tooltip, Typography } from '@mui/material';
import DisplayTable, {
  formatAddress,
  formatDateString,
  formatReferenceNumbers,
  getLocalTimeFromTimestamp,
  GridColDef,
} from 'src/components/table';
import { useParcelSubscriptionForOrg } from 'src/components/hooks/useSubscription';
import noPackagesImg from 'src/assets/no-packages.png';
import LoadingScreen from 'src/components/LoadingScreen';
import { MuiNavigationLink } from 'src/components/MuiLink';
import Filters from '../dashboard/filters/Filters';
import InfoIcon from '@mui/icons-material/Info';
import useStyles from '../dashboard/Dashboard.styles';
import moment from 'moment';

export default function AllDeliveries() {
  const classes = useStyles();
  const cursors = useRef<Map<number, string>>(new Map());
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [items, setItems] = useState<GetParcelModel[]>([]);

  const {
    value: deliveries,
    count,
    isLoading,
    lastUpdateTimestamp,
  } = useParcelSubscriptionForOrg<GetParcelModel>(
    `projections/web/webParcelProjections`,
    itemsPerPage,
    cursors.current.get(page)
  );

  const parcels = useMemo(() => {
    return deliveries;
  }, [deliveries]);

  const onRowsPerPageChanged = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage((page) => {
      cursors.current.set(
        (page = page + 1),
        deliveries[deliveries.length - 1].creationDate
      );
      return 0;
    });
  };

  const onPageChanged = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage((page) => {
      cursors.current.set(
        (page = page + 1),
        deliveries[deliveries.length - 1].creationDate
      );
      return newPage;
    });
  };

  useEffect(() => {
    if (!parcels) return;
    setItems(Object.values<GetParcelModel>(parcels));
  }, [lastUpdateTimestamp]);

  const columns: GridColDef<GetParcelModel>[] = [
    { field: 'orderId', headerName: 'Order ID', width: '9%' },
    {
      field: 'id',
      headerName: 'Package ID',
      width: '9%',
      valueGetter: (row: GetParcelModel) => {
        return (
          <MuiNavigationLink navigationTarget={`/deliveries/${row.id}`}>
            {row.id}
          </MuiNavigationLink>
        );
      },
    },
    {
      field: 'trackingId',
      headerName: 'Tracking ID',
      width: '7%',
      valueGetter: (row: GetParcelModel) => {
        return (
          <a target='_blank' href={`/tracking/${row.trackingId}`}>
            {row.trackingId}
          </a>
        );
      },
    },
    {
      field: 'referenceNumber',
      headerName: 'Reference No.',
      width: '9%',
      valueGetter: (row: GetParcelModel) => formatReferenceNumbers(row),
    },
    {
      field: 'pickupAddress',
      headerName: 'Pickup address',
      width: '17%',
      valueGetter: (row: GetParcelModel) =>
        formatAddress(row.parcelLocation?.pickupAddress),
    },
    {
      field: 'dropoffAddress',
      headerName: 'Dropoff address',
      width: '17%',
      valueGetter: (row: GetParcelModel) =>
        formatAddress(row.parcelLocation?.shippingAddress),
    },
    { field: 'receiverData.fullName', headerName: 'Receiver', width: '15%' },
    {
      field: 'creationDate',
      headerName: 'Creation time',
      valueGetter: (row: GetParcelModel) => formatDateString(row.creationDate),
    },
    {
      field: 'deliveryType',
      headerName: 'Delivery type',
      valueGetter: (row: any) => {
        const deliveryType =
          row.deliveryType === ParcelDeliveryType.Sameday
            ? 'Same-Day'
            : 'Next-Day';
        const cutoffTime = getLocalTimeFromTimestamp(
          row?.deadlineTime?.cutoff?.seconds
        );
        const cutoffTimeFormatted = moment(cutoffTime).format('h:mm A');
        const dueTime = getLocalTimeFromTimestamp(row?.deadline?.seconds);
        const dueTimeFormatted =
          moment(dueTime).format('DD/MM/YYYY h:mm A') || 'No ETA';
        const deliveredTimeFormatted = moment(row?.deliveryEnd).format(
          'DD/MM/YYYY h:mm A'
        );
        const isParcelDelivered = row.status === ParcelStatus.Delivered;
        const tooltipContent = (
          <span>
            Cutoff: {cutoffTimeFormatted} <br />{' '}
            {isParcelDelivered
              ? `Delivered on: ${deliveredTimeFormatted}`
              : `Delivery before: ${dueTimeFormatted}`}
          </span>
        );
        const tooltip = (
          <Tooltip title={tooltipContent}>
            <span>
              {deliveryType}
              <InfoIcon className={classes.tooltipIcon} />
            </span>
          </Tooltip>
        );
        return tooltip;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      labelClassName: (row) =>
        getStatusClassName(row.status, row.paymentStatus, row.paymentMethod),
      valueGetter: (row) =>
        getStatusName(row.status, row.paymentStatus, row.paymentMethod),
    },
  ];

  return (
    <>
      <Filters />
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {items.length > 0 ? (
            <Box sx={{ mt: 2 }}>
              <DisplayTable<GetParcelModel>
                idColumn={'orderId'}
                rows={items}
                columns={columns}
                itemsPerPage={itemsPerPage}
                totalItems={count}
                currentPage={page}
                handleChangePage={onPageChanged}
                rowsPerPage={itemsPerPage}
                handleChangeRowsPerPage={onRowsPerPageChanged}
              />
            </Box>
          ) : (
            <Box
              m={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img src={noPackagesImg} alt='No deliveries' />
              <Typography variant='h2'>No deliveries to display</Typography>
              <Typography
                variant='subtitle2'
                color='#808080'
                sx={{ marginBottom: 3 }}
              >
                You will see all deliveries in the system here.
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
}
