import { useEffect, useState } from 'react';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { Button, FormGroup, Typography, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';

import FormTextField from 'src/components/form/FormTextField';
import {
  DeleteIcon,
  DuplicateIcon,
  FragileIcon,
  PercentIcon,
  WeightIcon,
} from './icons';
import { IOSSwitch } from 'src/components/IOSSwitch';
import { GetVolume } from './packageUtils';
import { OrderParams, OrderParcelDetails } from 'src/services/api';

export interface PackageEditorProps {
  parcelNumber: number;
  onRemove: (packageNumber: number) => void;
  onDuplicate: (packageNumber: number) => void;
  canRemove: boolean;
  canDuplicate: boolean;
}

const useStyles = makeStyles((theme) => ({
  option: {
    cursor: 'default',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
  },
  headerTitle: {
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 800,
    textTransform: 'uppercase',
  },
  headerActions: {
    display: 'flex',
    alignContent: 'center',
    padding: 0,
    '& button': {
      padding: '8px 11px !important',
      minWidth: '24px',
      marginLeft: '8px',
      border: '1px solid #BFBFBF',
    },
    '& button[disabled]': {
      opacity: 0.3,
    },
  },
  removeButton: {
    border: '1px solid #CC4232 !important',
  },
  textField: {
    '& input': {
      padding: theme.spacing(1),
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  textFieldName: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14.4px',
    marginBottom: '6px',
    color: '#979797',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
  },
  inputGroup: {
    display: 'flex',
    gap: '.5em',
    alignItems: 'baseline',
    whiteSpace: 'nowrap',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
  },
  inputCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxContainer: {
    '& label': {
      marginLeft: 0,
      alignItems: 'baseline',
    },
  },
  checkboxWarningText: {
    fontSize: '13px',
    lineHeight: '16px',
    color: '#494949',
    marginLeft: theme.spacing(1),
  },
}));

export default function PackageEditor<T>(props: PackageEditorProps) {
  const classes = useStyles();
  const { control, formState, getValues } = useFormContext<OrderParams>();
  const values = getValues();
  const parcels = formState?.errors?.parcels;

  const [dimension_x, setDimension_x] = useState<number>(0);
  const [dimension_y, setDimension_y] = useState<number>(0);
  const [dimension_z, setDimension_z] = useState<number>(0);
  const [weight, setWeight] = useState<number>(0);
  const [packageErrors, setPackageErrors] = useState<
    OrderParcelDetails | undefined
  >(undefined);

  const volume =
    GetVolume(dimension_x || 0, dimension_y || 0, dimension_z || 0) || 0;

  const dimensionsError =
    (dimension_x && (dimension_x < 0 || dimension_x > 150)) ||
    (dimension_y && (dimension_y < 0 || dimension_y > 150)) ||
    (dimension_z && (dimension_z < 0 || dimension_z > 150));
  const emptyDimensionsError = parcels
    ? !dimension_x || !dimension_y || !dimension_z
    : false;

  const volumeError = volume > 0.45;
  const weightError = weight > 25;
  const emptyWeightError = parcels ? !weight : false;

  useEffect(() => {
    setWeight(Number(values.parcels[props.parcelNumber].weight));
    setDimension_x(Number(values.parcels[props.parcelNumber].dimension_x));
    setDimension_y(Number(values.parcels[props.parcelNumber].dimension_y));
    setDimension_z(Number(values.parcels[props.parcelNumber].dimension_z));
  }, [values]);

  useEffect(() => {
    try {
      setPackageErrors(
        (formState.errors.parcels as FieldErrors)[props.parcelNumber]
      );
    } catch {
      setPackageErrors(undefined);
    }
  }, [formState, setPackageErrors, props.parcelNumber]);

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.headerTitle}>
          Parcel {props.parcelNumber + 1}
        </Box>
        <Box className={classes.headerActions}>
          <Button
            id={'duplicateParcel' + props.parcelNumber}
            variant='outlined'
            color='inherit'
            disabled={!props.canDuplicate}
            onClick={() => props.onDuplicate(props.parcelNumber)}
          >
            <DuplicateIcon />
          </Button>
          <Button
            id={'removeParcel' + props.parcelNumber}
            variant='outlined'
            color='error'
            disabled={!props.canRemove}
            onClick={() => props.onRemove(props.parcelNumber)}
            className={classes.removeButton}
          >
            <DeleteIcon />
          </Button>
        </Box>
      </Box>
      <Box className={classes.inputContainer}>
        <Box className={classes.inputCol}>
          <Box className={classes.textFieldName}>*Weight</Box>
          <Box className={classes.inputGroup}>
            <FormTextField<T>
              id={`parcels.${props.parcelNumber}.weight` as any}
              rules={{
                required: true,
                validate: (value: any) =>
                  value && parseFloat(value) > 0 && parseFloat(value) <= 25,
                max: 25,
                min: 0.01,
                pattern: /^([0-9]+)([.]([0-9]{1,2}))?$/,
              }}
              TextField={{
                type: 'text',
                placeholder: 'Weight',
                className: classes.textField,
                InputProps: {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <WeightIcon />
                    </InputAdornment>
                  ),
                },
                sx: {
                  width: '9em',
                  whiteSpace: 'normal',
                },
              }}
              onChange={(e) => setWeight(parseFloat(e.target.value))}
              pattern={'[0-9.]'}
            ></FormTextField>
            kg
          </Box>
          <FormHelperText
            error={!!weightError || !!emptyWeightError}
            id={`parcels.${props.parcelNumber}.weight-helper-text`}
          >
            {emptyWeightError
              ? 'Please provide correct weight.'
              : weightError
                ? 'Weight exceeds the accepted value.'
                : ''}
          </FormHelperText>
          <FormHelperText>(max. 25kg)</FormHelperText>
        </Box>
        <Box className={classes.inputCol}>
          <Box className={classes.textFieldName}>*Dimensions</Box>
          <Box className={classes.inputGroup}>
            <FormTextField<T>
              id={`parcels.${props.parcelNumber}.dimension_x` as any}
              rules={{
                required: true,
                validate: (value: any) =>
                  value && parseFloat(value) > 0 && parseFloat(value) <= 150,
                max: 150,
                min: 0.1,
              }}
              TextField={{
                type: 'number',
                className: classes.textField,
                sx: {
                  width: '3em',
                  mozAppearance: 'textfield',
                },
                onInput: (e) => {
                  (e.target as HTMLInputElement).value = Math.max(
                    0,
                    parseInt((e.target as HTMLInputElement).value)
                  )
                    .toString()
                    .slice(0, 3);
                },
              }}
              onChange={(e) => setDimension_x(parseInt(e.target.value))}
            ></FormTextField>
            x
            <FormTextField<T>
              id={`parcels.${props.parcelNumber}.dimension_y` as any}
              rules={{
                required: true,
                validate: (value: any) =>
                  value && parseFloat(value) > 0 && parseFloat(value) <= 150,
                max: 150,
                min: 0.1,
              }}
              TextField={{
                type: 'number',
                className: classes.textField,
                sx: {
                  width: '3em',
                },
                onInput: (e) => {
                  (e.target as HTMLInputElement).value = Math.max(
                    0,
                    parseInt((e.target as HTMLInputElement).value)
                  )
                    .toString()
                    .slice(0, 3);
                },
              }}
              onChange={(e) => setDimension_y(parseInt(e.target.value))}
            ></FormTextField>
            x
            <FormTextField<T>
              id={`parcels.${props.parcelNumber}.dimension_z` as any}
              rules={{
                required: true,
                validate: (value: any) =>
                  value && parseFloat(value) > 0 && parseFloat(value) <= 150,
                max: 150,
                min: 0.1,
              }}
              TextField={{
                type: 'number',
                className: classes.textField,
                sx: {
                  width: '3em',
                },
                onInput: (e) => {
                  (e.target as HTMLInputElement).value = Math.max(
                    0,
                    parseInt((e.target as HTMLInputElement).value)
                  )
                    .toString()
                    .slice(0, 3);
                },
              }}
              onChange={(e) => setDimension_z(parseInt(e.target.value))}
            ></FormTextField>
            cm
          </Box>
          <FormHelperText
            error={!!dimensionsError || !!emptyDimensionsError || !!volumeError}
            id={`parcels.${props.parcelNumber}.dimension-helper-text`}
          >
            {emptyDimensionsError
              ? 'Please provide all dimensions.'
              : dimensionsError
                ? 'Dimensions exceed the accepted value.'
                : volumeError
                  ? 'Volume exceeds the accepted value (0.45 ㎥)'
                  : ''}
          </FormHelperText>
          <FormHelperText>(max. 150 cm each side)</FormHelperText>
        </Box>
        <Box className={classes.inputCol}>
          <Box className={classes.textFieldName}>Volume</Box>
          <Box
            className={classes.inputGroup}
            sx={{ alignItems: 'center', minHeight: '3.35rem' }}
          >
            <Typography variant='h3' sx={{ padding: '0 0 16px 0' }}>
              {volume} m3
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.checkboxContainer}>
        <FormGroup>
          <Box component='div' px={1} pt={2} pb={1}>
            <Controller
              name={`parcels.${props.parcelNumber}.requiresAttention`}
              defaultValue={false}
              control={control as any}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <FormControl
                      component='fieldset'
                      error={fieldState.invalid}
                    >
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            id='requiresAttention'
                            size='medium'
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        }
                        label={
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                              marginRight: '8px',
                            }}
                          >
                            <FragileIcon />
                            Package requires attention
                          </Box>
                        }
                        labelPlacement='start'
                      />
                      {fieldState.error && (
                        <FormHelperText
                          id={`parcels.${props.parcelNumber}.requiresAttention-helper-text`}
                        >
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </>
                );
              }}
            />
          </Box>
          <Box className={classes.checkboxWarningText}>
            Enable this option if the shipment requires special care during
            transportation, contains liquids or food, or is very fragile. This
            may increase the final price of delivery.
          </Box>
        </FormGroup>
        <FormGroup>
          <Box component='div' px={1} pt={2} pb={1}>
            <Controller
              name={`parcels.${props.parcelNumber}.containsAlcohol`}
              defaultValue={false}
              control={control as any}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <FormControl
                      component='fieldset'
                      error={fieldState.invalid}
                    >
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            id='containsAlcohol'
                            size='medium'
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        }
                        label={
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                              marginRight: '8px',
                            }}
                          >
                            <PercentIcon />
                            Contains alcoholic beverages
                          </Box>
                        }
                        labelPlacement='start'
                      />
                      {fieldState.error && (
                        <FormHelperText
                          id={`parcels.${props.parcelNumber}.containsAlcohol-helper-text`}
                        >
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </>
                );
              }}
            />
          </Box>
          <Box className={classes.checkboxWarningText}>
            Shipments containing alcoholic beverages may only be delivered to
            adult recipients. Our driver will check the age of the recipient.
          </Box>
        </FormGroup>
      </Box>
    </>
  );
}
