import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import useStyles from './TiffanyDeliveryTracker.styles';
import {
  CourierPosition,
  DeliveryTrackingData,
  TrackerRouteParams,
} from './types';
import { ParcelStatus } from 'src/types/delivery/Delivery';
import StatusTimeline from './StatusTimeline';
import TrackerMap from './TrackerMap';
import { useSubscription } from 'src/components/hooks/useSubscription';
import FormTextField from 'src/components/form/FormTextField';
import trackerImage from 'src/assets/tiffany-tracker-img.jpg';
import poweredbyImage from 'src/assets/poweredby-img.png';

import { useForm, FormProvider } from 'react-hook-form';
import { TrackingService } from '../../services/api';
import { toast } from 'react-toastify';
import { formatAddress } from '../../components/table';

export default function Tracker() {
  const classes = useStyles();
  const history = useHistory();
  const formMethods = useForm();
  const [mapHandler, setMapHandler] = useState<any>({});
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<string>();
  const [courierPosition, setCourierPosition] = useState<CourierPosition>();
  const [courierPhoto, setCourierPhoto] = useState<string>();

  let { id } = useParams<TrackerRouteParams>();
  const COURIER_DATA_FETCH_INTERVAL = 10000;

  const { value: trackingDatas, isLoading: trackingLoadingData } =
    useSubscription<DeliveryTrackingData>(
      `projections/tracking/trackingParcelProjections`,
      [
        {
          field: 'id',
          operator: '==',
          value: id,
        },
      ]
    );
  const trackingData =
    (trackingDatas && trackingDatas.length > 0 && trackingDatas[0]) || null;

  const updateMapBounds = () => {
    const { map, maps } = mapHandler;

    if (map && maps && trackingData) {
      const points = [
        new maps.LatLng(
          trackingData.shippingAddress.latitude,
          trackingData.shippingAddress.longitude
        ),
      ];

      if (!courierPosition && trackingData.status !== ParcelStatus.Delivered) {
        points.push(
          new maps.LatLng(
            trackingData.pickupAddress.latitude,
            trackingData.pickupAddress.longitude
          )
        );
      }

      if (courierPosition && trackingData.status !== ParcelStatus.Delivered) {
        points.push(
          new maps.LatLng(courierPosition.latitude, courierPosition.longitude)
        );
      }

      let bounds = new maps.LatLngBounds();
      for (let i = 0; i < points.length; i++) {
        bounds.extend(points[i]);
      }
      map.fitBounds(bounds, { top: 55, bottom: 55, right: 130, left: 130 });
      setLoading(false);
    }
  };

  const fetchCourierPosition = async () => {
    if (id && trackingData?.status !== ParcelStatus.Delivered) {
      try {
        const courierData = await TrackingService.getCourierPosition(id);
        courierData?.allowTracking && setCourierPosition(courierData.position);
      } catch (e: any) {
        console.error('Error', e.message);
        toast.error(
          `Could not get courier position: ${e.message.toLowerCase()}`,
          { hideProgressBar: true }
        );
      }
    }
  };

  const fetchCourierPhoto = async () => {
    if (id && trackingData?.status !== ParcelStatus.Delivered) {
      try {
        const courierData = await TrackingService.getCourierPhoto(id);
        courierData && setCourierPhoto(courierData?.courierPhotoBase64);
      } catch (e: any) {
        console.error('Error', e.message);
        toast.error(`Could not get courier photo: ${e.message.toLowerCase()}`, {
          hideProgressBar: true,
        });
      }
    }
  };

  useEffect(() => {
    if (trackingLoadingData === false && trackingData === null && id) {
      setError('Invalid tracking ID');
      setLoading(false);
    }
  }, [trackingLoadingData]);

  useEffect(() => {
    if (id) setLoading(true);
  }, []);

  useEffect(() => {
    updateMapBounds();
  }, [mapHandler, trackingLoadingData]);

  useEffect(() => {
    fetchCourierPhoto();
  }, [trackingDatas]);

  useEffect(() => {
    fetchCourierPosition();
  }, [id]);

  useEffect(() => {
    const myInterval = setInterval(
      fetchCourierPosition,
      COURIER_DATA_FETCH_INTERVAL
    );
    return () => {
      clearInterval(myInterval);
    };
  }, []);

  return (
    <>
      <Box component='div' className={classes.bg} />
      <Paper className={classes.paper} elevation={2} square>
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='row'
          gap={10}
          maxWidth='900px'
          margin='0 auto'
        >
          <Box
            component='img'
            sx={{
              height: '300',
              width: 'auto',
              maxHeight: 300,
              display: { xs: 'none', md: 'block' },
            }}
            alt='track your Deliver In Person parcel'
            src={trackerImage}
          />
          <Box mt={3}>
            <h1>Track Your Parcel</h1>
            <Typography fontSize='1rem' paddingBottom={5}>
              Want to see exactly where your parcel is, right now? We'll have
              sent you a Tracking ID and link by either SMS or email. Be sure to
              check your spam folder if it’s not in your inbox!
            </Typography>
            <Box paddingBottom={3}>
              <FormProvider {...formMethods}>
                <form
                  className={classes.trackingIdForm}
                  onSubmit={formMethods.handleSubmit((data) => {
                    const trackingId = Object.values(data)[0];
                    if (trackingId) {
                      history.push(`/tracking-tco/${trackingId}`);
                      history.go(0);
                    }
                  })}
                >
                  <FormTextField<TrackerRouteParams>
                    pattern='[a-zA-Z0-9-]'
                    id='tracker-input-field'
                    rules={{
                      required: true,
                    }}
                    TextField={{
                      className: classes.trackingIdFormInput,
                      type: 'text',
                      placeholder: 'Enter Tracking ID',
                      value: id,
                    }}
                  ></FormTextField>
                  <Button
                    id='tracker-submit-button'
                    className={classes.trackingIdFormBtn}
                    variant='contained'
                    type='submit'
                    disabled={loading}
                  >
                    Track my parcel
                  </Button>
                </form>
              </FormProvider>
            </Box>

            {loading && (
              <Box
                display='flex'
                alignItems='center'
                flexDirection='column'
                m={10}
              >
                <CircularProgress size={64} />
              </Box>
            )}
            {error && (
              <Box mt={3}>
                <Alert severity='error' id='tracker-error-message'>
                  {error}
                </Alert>
              </Box>
            )}
          </Box>
        </Box>

        {trackingData && (
          <>
            <Box className={classes.mapContainter}>
              <TrackerMap
                pickupAddress={trackingData.pickupAddress}
                courierLocation={courierPosition}
                courierPhoto={courierPhoto}
                shippingAddress={trackingData.shippingAddress}
                onLoad={(map, maps) => setMapHandler({ map, maps })}
              />
              <div className={classes.mapResetBoundsButtonWrapper}>
                <Button
                  type='submit'
                  disabled={loading}
                  onClick={updateMapBounds}
                >
                  Reset view
                </Button>
              </div>
            </Box>

            <Grid
              container
              marginTop={2}
              className={classes.detailsContainer}
              spacing={1.5}
            >
              <Grid item xs={12} md={4}>
                <div className={classes.statusWrapper}>
                  <Box style={{ display: 'inline-block' }}>
                    <Typography
                      className={classes.detailsContentTextSecondary}
                      id='tracker-status-header'
                    >
                      DELIVERY STATUS
                    </Typography>
                    <StatusTimeline
                      statusHistory={trackingData.statusHistory}
                      deadline={trackingData.deadline.seconds}
                      color='#70D8CC'
                    />
                  </Box>
                </div>
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container className={classes.contentContainer}>
                  <Grid item className={classes.contentWrapper} xs={12} sm={6}>
                    <Box style={{ display: 'inline-block' }}>
                      <Typography
                        className={classes.detailsContentTextSecondary}
                        id='tracker-sender-header'
                      >
                        SEND FROM
                      </Typography>
                      <Typography
                        className={classes.detailsContentTextDefault}
                        id='tracker-sender-name'
                      >
                        {trackingData.senderName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    className={classes.detailsContentWrapper}
                    xs={12}
                    sm={6}
                  >
                    <Typography
                      className={classes.detailsContentTextSecondary}
                      id='tracker-receiver-header'
                    >
                      DELIVER TO
                    </Typography>
                    <Typography
                      className={classes.detailsContentTextDefault}
                      id='tracker-receiver-name'
                    >
                      {trackingData.receiverName}
                    </Typography>
                    <Typography
                      className={classes.detailsContentTextDefault}
                      id='tracker-receiver-address-line1'
                    >
                      {formatAddress(trackingData.shippingAddress)}
                    </Typography>
                    <Typography
                      className={classes.detailsContentTextDefault}
                      id='tracker-receiver-address-state'
                    >
                      {trackingData.shippingAddress.state}{' '}
                      {trackingData.shippingAddress.postcode}
                    </Typography>
                    <Typography
                      className={classes.detailsContentTextDefault}
                      id='tracker-receiver-address-city'
                    >
                      {trackingData.shippingAddress.city}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
      <a href='https://deliverinperson.com'>
        <Box
          className={classes.bottomImage}
          component='img'
          alt='powered by Deliver In Person'
          src={poweredbyImage}
        />
      </a>
    </>
  );
}
